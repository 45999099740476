<template>
    <b-card no-body class="main-card mx-auto mb-3">
        <div class="badge pl-1">{{ setListingType(classified_ad.listing_type) }}</div>
        <router-link :to="{ name: 'ad', params: { ad_id: classified_ad.id }}">
            <b-img v-if="classified_ad.main_photo" class="main-photo" :src="`/api/user/v1/image/medium_${classified_ad.main_photo}`"/>
            <img v-else class="main-photo" :src="require('@/assets/images/esoseska/no-image.jpg')"/>
        </router-link>
        <div class="container px-2 py-1 d-flex flex-column card-content">
            <div class="d-flex justify-content-between">
                <router-link :to="{ name: 'ad', params: { ad_id: classified_ad.id }}" style="width: 60%;">
                    <div class="title">{{classified_ad.title.toUpperCase()}}</div>
                </router-link>
                <div class="date">{{dayjs(classified_ad.added_date).format("DD.MM.YYYY")}}</div>
            </div>
            <div class="mt-auto pt-1 d-flex justify-content-between align-items-center">
                <div>
                    <router-link :to="{ name: 'ad', params: { ad_id: classified_ad.id }}">
                        > več o tem
                    </router-link>
                </div>
                <div v-if="classified_ad.listing_type !== 4">
                    <div v-if="classified_ad.price_offer" class="price">PO DOGOVORU</div>
                    <div v-else class="price">{{$convertPriceIntegerToFloat(classified_ad.price)}} €</div>
                </div>
            </div>
        </div>
    </b-card>
</template>

<script>
    import {BImg, BCard} from 'bootstrap-vue'
    export default
        {
            components: {BImg, BCard},
            props: {
                classified_ad: {
                    type: Object,
                    required: true
                }
            },
            methods: {
                setListingType(listing_type) {
                    if (listing_type === 0 || listing_type === '0') return 'PRODAM'
                    else if (listing_type === 1 || listing_type === '1') return 'KUPIM'
                    else if (listing_type === 2 || listing_type === '2') return 'ZAMENJAM'
                    else if (listing_type === 3 || listing_type === '3') return 'ODDAM'
                    else if (listing_type === 4 || listing_type === '4') return 'PODARIM'
                    else return ''
                }
            }
        }
</script>

<style scoped>
.badge {
  border-radius: 0 !important;
  position: absolute;
  top: -1.048rem;
  background-color: #72A5D8;
  font-family: 'Encode Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  padding-right: 1.5rem;
  clip-path: polygon(0 0, 0 100%, 100% 100%, 93% 100%, 100% 0);
}
.title {
  font-family: 'Encode Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 23px;
  color: black;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
}
a {
  font-family: 'Encode Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 21px;
  color: #72A5D8;
}
.price {
  font-family: 'Encode Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
}
.card-content{
  background: #f8f8f8;
}
.main-card {
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  border: none !important;
  border-radius:0;
  box-shadow: none;
}
.container {
  flex: 1;
}
.main-photo {
  width: 100%;
  height: 255px;
  object-fit: cover;
}
.date {
  font-weight: 500;
  font-size: 17px;
  line-height: 21px;
  color: #72A5D8;
}
</style>