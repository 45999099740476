<template>
    <b-overlay :show='showLoader' style="position: relative;">
        <filters :slider="sliderValues" :categories="categories" @on-slider-change="onPriceSliderChange" @on-select="onSelect" @remove-filters="removeFilters"/>
        <blue-background class=""/>
        <h3 class="pt-1" style="color: #0000004D; font-size: 19px; font-weight: 700;">VSE OBJAVE</h3>
        <b-row class="mt-3" style="">
            <template v-if="c2cItems && c2cItems.length > 0">
                <b-col class="d-flex" cols="12" sm="6" md='6' lg="4" :key="index" v-for="(item, index) in c2cItems">
                    <ClassifiedAdCard :classified_ad="item" />
                </b-col>
            </template>
            <template v-else>
                <b-col class="text-center">
                    <h3>V tej kategoriji ni oglasov</h3>
                </b-col>
            </template>
        </b-row>
        <pagination v-if="pagination" v-model="pagination"/>
        <news-carousel class="mt-2"/>
    </b-overlay>
</template>

<script>
    import ClassifiedAdCard from '@/views/Components/ClassifiedAdCard'
    import {BRow, BCol, BOverlay} from 'bootstrap-vue'
    import Pagination from '@/views/Components/Pagination'
    import BlueBackground from '@/views/Components/BlueBackground'
    import Filters from '@/views/Components/Filters'
    import newsCarousel from '@/views/Components/NewsCarousel'
    export default {
        components: {
            Filters,
            BlueBackground,
            Pagination,
            ClassifiedAdCard,
            BRow,
            BCol,
            BOverlay,
            newsCarousel
        },
        data() {
            return {
                categories: [],
                c2cItems: [],
                showLoader: false,
                filter: {
                    listingType: '',
                    condition: '',
                    categoryId: ''
                },
                listingTypes: [
                    {id: 0, title: 'PRODAM'},
                    {id: 1, title: 'KUPIM'},
                    {id: 2, title: 'ZAMENJAM'},
                    {id: 3, title: 'ODDAM'},
                    {id: 4, title: 'PODARIM'}
                ],
                pagination: null,
                created: false,
                sliderValues: {
                    value: [0, 100],
                    min: 0,
                    max: 100,
                    interval: 1
                },
                isLoadingAds: false,
                backForwardNavigation: false
            }
        },
        methods: {
            getQueryParams() {
                const page = this.$route.query.page ?? 1
                const perPage = this.$route.query.perPage ?? 9
                const listingType = this.$route.query.listingType ?? ''
                const condition = this.$route.query.condition ?? ''
                const categoryID = this.$route.query.category_id ?? ''
                const searchQuery = this.$route.query.search ?? ''
                const priceFrom = this.$route.query.price_from ?? ''
                const priceTo = this.$route.query.price_to ?? ''

                const pageQueryParam = (page ? `&page=${page}&` : '')
                const perPageQueryParam = (perPage ? `&perPage=${perPage}&` : '')
                const listeningTypeToQueryParam = (listingType ? `&listing_type=${listingType}&` : '')
                const conditionToQueryParam = (condition ? `&condition=${condition}&` : '')
                const selectedCategoryQueryParam = (categoryID ? `&category_id=${categoryID}&` : '')
                const searchQueryParam = (searchQuery ? `&search=${searchQuery}&` : '')
                const priceFromQueryParam = (priceFrom ? `&price.gte=${priceFrom}&` : '')
                const priceToQueryParam = (priceTo ? `&price.lte=${priceTo}&` : '')

                const p = [
                    pageQueryParam,
                    perPageQueryParam,
                    listeningTypeToQueryParam,
                    conditionToQueryParam,
                    selectedCategoryQueryParam,
                    searchQueryParam,
                    priceFromQueryParam,
                    priceToQueryParam
                ]
                return p.join('')
            },
            async loadC2CAds() {
                const thisIns = this
                thisIns.showLoader = true

                const queryParams = this.getQueryParams()

                await thisIns.$http.get(`/api/user/v1/es/c2c/?${queryParams}`)
                    .then(res => {
                        this.c2cItems = res.data.items ?? []
                        this.pagination = res.data.pagination

                        const minPrice = Number(res.data.min_price ?? 0) * 1
                        const maxPrice = Number(res.data.max_price ?? 0) * 1

                        if (!this.created) {
                            // when a page is first loaded, it is checked to see if they already exist price_from and price_to in URL
                            // if exist already call ES again without conditions and get min and max price of all c2c items
                            // we need this to filter the price in the slider
                            if (this.$route.query.price_from && this.$route.query.price_to) {
                                thisIns.$http.get('/api/user/v1/es/c2c/')
                                    .then(res => {

                                        const min = Number(res.data.min_price ?? 0) * 1
                                        const max = Number(res.data.max_price ?? 0) * 1

                                        const minPriceFromUrl = Number(this.$route.query.price_from)
                                        const maxPriceFromUrl = Number(this.$route.query.price_to)

                                        this.sliderValues.value = [minPriceFromUrl, maxPriceFromUrl]
                                        this.sliderValues.min = min
                                        this.sliderValues.max = max
                                    })
                                    .catch(error => {
                                        thisIns.$printError(JSON.stringify(error))
                                        thisIns.$printError(`Prišlo je do napake pri nalaganju podatkov\n${error.message}`)
                                    })
                            } else {
                                this.sliderValues.value = [minPrice, maxPrice]
                                this.sliderValues.min = minPrice
                                this.sliderValues.max = maxPrice
                            }
                        }
                    })
                    .catch(error => {
                        thisIns.$printError(`Prišlo je do napake pri nalaganju podatkov\n${error.message}`)
                    }).finally(() => {
                        thisIns.showLoader = false
                        thisIns.created = true
                        thisIns.isLoadingAds = false
                    })
            },
            async loadCategories() {
                const thisIns = this
                thisIns.showLoader = true

                try {
                    const loadCategoriesResult = await thisIns.$http.get('/api/user/v1/c2c/category/')
                    thisIns.categories = loadCategoriesResult.data

                } catch (error) {
                    thisIns.$printError(`Prišlo je do napake pri nalaganju podatkov\n${error.message}`)
                } finally {
                    thisIns.showLoader = false
                }
            },
            async onPriceSliderChange(sliderValues) {
                this.backForwardNavigation = false

                let valuePrevFrom = 0
                let valuePrevTo = 0

                if (this.$route.query) {
                    valuePrevFrom = parseInt(this.$route.query.price_from)
                    valuePrevTo = parseInt(this.$route.query.price_to)
                }

                if (valuePrevFrom !== sliderValues.value_from || valuePrevTo !== sliderValues.value_to) {
                    const query = Object.assign({}, this.$route.query)
                    query.price_from = sliderValues.value_from
                    query.price_to = sliderValues.value_to
                    await this.$router.replace({ query })
                } else {
                    return
                }
                await this.loadC2CAds()
                this.backForwardNavigation = true
            },
            async onSelect(name, val) {
                if (name === 'listingType') {
                    if (this.$route.query.listingType === val) return
                    await this.$router.replace({path: this.$route.fullPath, query:  {listingType: val}})
                    await this.loadC2CAds()
                } else if (name === 'condition') {
                    if (this.$route.query.condition === val) return
                    await this.$router.replace({path: this.$route.fullPath, query:  {condition: val}})
                    await this.loadC2CAds()
                } else if (name === 'category') {
                    if (this.$route.query.category_id === val.id) return
                    await this.$router.replace({path: this.$route.fullPath, query:  {category_id: val.id}})
                    await this.loadC2CAds()
                }
            },
            async listingTypeChange(val) {
                if (this.$route.query.listingType === val) return
                await this.$router.replace({path: this.$route.fullPath, query:  {listingType: val}})
                await this.loadC2CAds()
            },
            async removeFilters() {
                this.created = false
                this.filter = {
                    listingType: '',
                    condition: '',
                    categoryId: ''
                }
                if (this.$route.fullPath !== '/oglasi') await this.$router.replace({})
                await this.loadC2CAds()
                this.created = true
            }
        },
        async mounted() {
            await this.loadC2CAds()
            await this.loadCategories()
            if (this.$route.query.listingType) this.filter.listingType = Number(this.$route.query.listingType)
            this.created = true
        },
        watch: {
            async 'pagination.current_page'() {
                if (!this.created) return
                const currentPage = (this.pagination ? this.pagination.current_page : 1)
                if (currentPage !== 0 && this.$route.fullPath !== `/oglasi?page=${currentPage}`) {
                    await this.$router.replace({path: this.$route.fullPath, query:  {page: currentPage}})
                    await this.loadC2CAds()
                }
            },
            '$route.query.search'(val) {
                if (val) {
                    this.loadC2CAds()
                }
            }
        }
    }
</script>

<style scoped>
.to-all {
  background-color: #EAF3F9;
  margin-bottom: 3rem;
}
.carousel-indicators li {
    background-color: black;
}
</style>
